<template>
  <b-row>
    <b-col cols="12">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Não esqueça de verificar a quantidade disponível em estoque!
        </h4>
        <div class="alert-body">
          <span>Ao adicionar créditos está concordando com nossos <b><a
            class="link"
            @click.stop="$router.push({ name: 'Termos' })"
          >[Termos]</a></b> e ciente sobre o <b><a
            class="link"
            @click.stop="$router.push({ name: 'Estoque' })"
          >[Estoque Disponível]</a></b> em nosso site.</span>
        </div>
      </b-alert>
    </b-col>

    <b-col cols="12">
      <h6 class="mb-2 text-muted">
        Formas de Pagamento
      </h6>
    </b-col>
    <b-col cols="12">
      <b-card-group class="mb-0 d-flex justify-content-center">
        <b-row>
          <!-- card 1 -->
          <b-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
          >
            <b-card
              style="max-width: 390px"
              :img-src="require('@/assets/images/PaymentMethods/pix-original.jpg')"
              img-alt="Pix"
              img-height="192px"
              img-top
              no-body
            >
              <b-card-body>
                <b-card-title>Pix</b-card-title>
                <b-card-text>
                  Pix é um novo meio de pagamentos que permite fazer transferências
                  e pagamentos de forma instantânea em qualquer dia e horário.
                </b-card-text>
              </b-card-body>
              <b-card-footer>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  variant="primary"
                  @click.stop="$router.push({ name: 'Adicionar Saldo (Pix)' })"
                >
                  Selecionar
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>

          <!-- card 2 -->
          <b-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
          >
            <b-card
              style="max-width: 390px"
              :img-src="require('@/assets/images/PaymentMethods/picpay.png')"
              img-alt="PicPay"
              img-height="192px"
              img-top
              no-body
            >
              <b-card-body>
                <b-card-title>PicPay</b-card-title>
                <b-card-text>
                  PicPay é um aplicativo utilizado para receber e enviar dinheiro,
                  sem taxas.
                </b-card-text>
              </b-card-body>
              <b-card-footer>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  variant="primary"
                  @click.stop="$router.push({ name: 'Adicionar Saldo (PicPay)' })"
                >
                  Selecionar
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
          >
            <!-- card 3 -->
            <b-card
              style="max-width: 390px"
              :img-src="require('@/assets/images/PaymentMethods/mercadopago.jpg')"
              img-alt="Mercado Pago"
              img-height="192px"
              img-top
              no-body
            >
              <b-card-body>
                <b-card-title>Mercado Pago</b-card-title>
                <b-card-text>
                  O Mercado Pago é a carteira digital do Mercado Livre.
                </b-card-text>
              </b-card-body>
              <b-card-footer>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  variant="primary"
                  @click.stop="$router.push({ name: 'Adicionar Saldo (Mercado Pago)' })"
                >
                  Selecionar
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-card-group>
    </b-col>

    <b-col
      cols="12"
      class="mt-1"
    >
      <BalanceHistory />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BButton,
  BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BalanceHistory from './BalanceHistory/Index.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BButton,
    BalanceHistory,
    BAlert
  },
  directives: {
    Ripple
  }
}
</script>
