<template>
  <b-card class="mt-1">
    <b-card-title>
      <div>
        Saldo total adicionado: {{ userData.total_balance | currency }}
      </div>
    </b-card-title>
    <TablePagination
      v-model="items"
      url="/user/balance/history"
      :per-page="10"
      :fields="fields"
    >
      <template v-slot:action="data">
        <span v-if="data.items.action === 'addition'">Saldo Adicionado</span>
        <span v-if="data.items.action === 'subtraction'">Saldo Retirado</span>
      </template>

      <template v-slot:amount="data">
        {{ data.items.amount | currency }}
      </template>

      <template v-slot:type="data">
        <span v-if="data.items.type === 'Manually'">Manual</span>
        <span v-else-if="data.items.type === 'Refund'">Reembolso Integral</span>
        <span v-else-if="data.items.type === 'Parcial Refund'">Reembolso Parcial</span>
        <span v-else>{{ data.items.type }}</span>
        <span v-if="data.items.old_history"> &nbsp;(Histórico Antigo) </span>
      </template>

      <template v-slot:created_at="data">
        {{ data.items.created_at | date }}
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BCardTitle } from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    BCardTitle,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 6)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    show: false,
    userData: JSON.parse(localStorage.getItem('userData')),
    items: [],
    fields: [
      {
        key: 'action',
        label: 'Ação'
      },
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'type',
        label: 'Tipo de Ação'
      },
      {
        key: 'created_at',
        label: 'Data de realização'
      }
    ]
  })
}
</script>
