<template>
  <section>
    <b-tabs
      content-class="mt-1"
      align="center"
    >
      <b-tab title="Saldos Adicionados">
        <BalanceHistory />
      </b-tab>

      <b-tab title="Reembolsos">
        <BalanceHistoryRefund />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import BalanceHistory from './BalanceHistory.vue'
import BalanceHistoryRefund from './BalanceHistoryRefund.vue'

export default {
  components: {
    BTabs,
    BTab,
    BalanceHistory,
    BalanceHistoryRefund
  }
}
</script>
